// CurrencyRates.js
const CurrencyRates = {
  HKD: {
    rate: 1.03,
    rateCountry: "HKD",
  },
  USD: {
    rate: 7.8,
    rateCountry: "USD",
  },
  MOP: {
    rate: 1,
    rateCountry: "MOP",
  },
  EUR: {
    rate: 8.53,
    rateCountry: "EUR",
  },
  // ... 其他货币
};

export default CurrencyRates;
